<template>
	<v-row>
		<v-col cols="12" md="12" lg="12">
			<p
				class="
					text-h5
					font-weight-black
					text-uppercase
					d-flex
					align-center
				"
			>
				<v-icon
					large
					color="primary"
					class="mr-2"
					v-text="menu_info.icon"
				></v-icon>
				{{ menu_info.title_en }}
			</p>
			<v-divider></v-divider>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Header",
	data() {
		return {
			menu_info: this.$store.getters.menu,
		};
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style scoped>
</style>