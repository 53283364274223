<template>
  <v-container>
    <div id="container"></div>
  </v-container>
</template>

<script>
import { Chart } from "@antv/g2";
import { listContestScore } from "@/api/solar/contest";
export default {
  name: "BarGraph",
  data() {
    return {
      menu_info: this.$store.getters.menu,
      team_list: this.$store.getters.team_list,
      team_dict: {},
      contest: [],
      chart: null,
    };
  },
  created() {},

  mounted() {
    this.setChart();
  },

  watch: {
    // 监听store中的状态
    "$store.getters.team_list": {
      handler: function (newVal) {
        this.team_list = newVal;
        this.setChart();
      },
    },
  },

  methods: {
    setChart() {
      if (this.team_list) {
        this.setData();
        this.chart = new Chart({
          container: "container",
          autoFit: true,
          height: 500,
        });
        this.chart.data(this.contest);
        this.chart.scale("score", {
          min: 0,
          max: 100,
          alias: this.menu_info.title_en
        });

        this.chart.interaction("active-region");
        this.chart.coordinate().transpose();
        this.chart.tooltip({
          shared: true,
          showMarkers: false,
          customItems: (items) => {
            items.forEach((item)=>{
              item.value = Number(item.value).toFixed(3)
            })
            return items;
          },
        });
        this.chart
            .interval()
            .position("team*score")
            .color("cornflowerblue")
            .label('score', (value) => {
              return {
                content: value.toFixed(3),
              };
            });
        this.chart.render();
      }
    },
    setData() {
      this.team_list.forEach((item) => {
        this.team_dict[item["name_en"]] = {
          team: item["name_en"],
          score: 0,
        };
      });
      listContestScore({ type: this.menu_info["name"] })
          .then((res) => {
            res.forEach((item) => {
              this.team_dict[item["team"]]["score"] = item["score"];
            });

            // 提取对象的值添加到数组
            const contest = [];
            Object.values(this.team_dict).forEach((value) => {
              contest.push(value);
            });

            // 排序
            function sortContest(a, b) {
              return a.score - b.score;
            }
            contest.sort(sortContest);
            this.chart.changeData(contest);
          })
          .catch();
    },
  },
};
</script>

<style scoped>
</style>